import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useQuery, useMutation } from '@apollo/client'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Pagination from '@material-ui/lab/Pagination'

import Title from '../components/Title'
import Spinner from '../components/spinner'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import {
  CampaignsDocument,
} from '../generated'
import Button from '@material-ui/core/Button'
import { FormControlLabel, Switch } from '@material-ui/core'
import { CampaignEditor } from './CampaignEditor'

import { Campaign } from '../generated';

import { gql } from '@apollo/client';

const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      id
      name
      description
      startDate
      endDate
    }
  }
`;



const useStyles = makeStyles((theme) => ({
  table: { minWidth: 650 },
  toLeft: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
  clickableRow: {
    cursor: 'pointer',
  },
}))


const CampaignsTable = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles()
  const headers = [
    'Id',
    'Name',
  ]

  return (
    <Paper style={{ width: '100%' }}>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headers.map((h) => (
                <TableCell key={h}>{h}</TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

const CampaignsScreen = () => {
  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN);
  const [open, setOpen] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  // const [loyaltyOnly, setLoyaltyOnly] = useState(false)
  // const [filterPhone, setFilterPhone] = useState('')
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }
  
  const Campaigns = () => {{ 
    const { loading, data, error, refetch } = useQuery(CampaignsDocument)
  
    useEffect(() => {
      if (data) refetch()
    }, [data, refetch])

    // useEffect(() => {
    //   if (countData) {
    //     countRefetch()
    //     console.log('countData', countData)
    //     setTotal(countData.consentedCustomersCount || 0)
    //   }
    // }, [countData, countRefetch])
  
    const formatDate = (date: string) => date.replace('T', ' ').slice(0, 16)

    const handleRowClick = (campaign: Campaign) => {
      setSelectedCampaign(campaign);
      setIsEditorOpen(true);
    };


    if (loading || !data) return <><Spinner /></>
    if (error) return <p>Error : {JSON.stringify(error, null, 2)}</p>
  
    return (
      <>
        {data.campaigns.map((campaign: any) => (
        <TableRow key={campaign.id} onClick={() => handleRowClick(campaign)} className="clickable-row">
            <TableCell>{campaign.name}</TableCell>
            <TableCell>{campaign.description}</TableCell>
          </TableRow>
        ))}
      </>
    )
  }
};

  const handleEditorClose = () => {
    setIsEditorOpen(false);
    setSelectedCampaign(null);
  };
  const onValueChange = (value: any, key: keyof Campaign) => {
    if (selectedCampaign) {
      const updatedCampaign = {
        ...selectedCampaign,
        [key]: value,
      };
      setSelectedCampaign(updatedCampaign);
      updateCampaign({
        variables: {
          input: {
            id: updatedCampaign.id,
            name: updatedCampaign.name,
            description: updatedCampaign.description,
            startDate: updatedCampaign.startDate,
            endDate: updatedCampaign.endDate,
          },
        },
      });
    }
  }

  // const handleFilterPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setFilterPhone(event.target.value);
  // };

  return (
    <>
      <Grid container>
        {(!isEditorOpen || !selectedCampaign) && (
          <>
            <Grid item xs={12}>
              <Title>Campaigns</Title>
            </Grid>
            <Grid item xs={6}>
              {/* <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    onChange={() =>
                      setLoyaltyOnly((prevLoyaltyOnly) => !prevLoyaltyOnly)
                    }
                    value={loyaltyOnly}
                    checked={loyaltyOnly}
                  />
                }
                label="Loyalty customers only"
                labelPlacement="start"
              /> */}
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  to={`/campaigns/create`}
                  variant="contained"
                  color="primary"
                  component={Link}
                  style={{ marginTop: '12px' }}
                >
                  + CREATE CAMPAIGN
                </Button>
              </Box>
            </Grid>

            <CampaignsTable>
              <Campaigns />
            </CampaignsTable>
            <Grid item xs={12}>
              <Pagination siblingCount={6} count={Math.ceil(total/pageSize)} page={page} onChange={handleChange} />
            </Grid>
          </>
        )}
            
        {isEditorOpen && selectedCampaign && (
        <CampaignEditor
          campaign={selectedCampaign}
          editMode={true}
          updating={false}
          onValueChange={onValueChange}
          onClose={handleEditorClose}
        />
        )}

      </Grid>

      {/* {open && (
        <CreateDiscount open={open} setOpen={setOpen} activeOnly={activeOnly} />
      )} */}
    </>
  )
}

export default CampaignsScreen
