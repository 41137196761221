import React from 'react'
import './App.css'

import { Routes, Route } from 'react-router-dom'
import { Protected, LoginScreen } from './Auth'

import { InventoryScreen } from './Inventory'
import { MenuItemsScreen } from './MenuItems'
import {
  ProductsScreen,
  SingleProductScreen,
  AddProductScreen,
} from './Products'
import { BatchGenerateScreen, DiscountsScreen } from './Discounts'
import { OrdersScreen, SingleOrderScreen } from './Orders'
import { MachinesScreen, SingleMachineScreen } from './Machines'
import { ErrorsScreen } from './Errors'
import { CustomerssScreen, } from './Customers'
import { CampaignsScreen, AddCampaignScreen } from './Campaigns'
import {
  SettingsScreen,
  MachineLocationsScreen,
  MachineLocationScreen,
  LocationCategoriesScreen,
  LocationCategoryScreen,
  LocationTypesScreen,
  LocationTypeScreen,
  GeographicalLocationScreen,
  GeographicalLocationsScreen,
} from './Settings'


import { Dashboard } from './Dashboard'
import { ApolloWrapper } from './ApolloWrapper'
import { MultilogProvider } from './context/multilog'
import path from 'path'

const App = () => {
  const protectedRoutes = [
    { path: '/products/create', component: AddProductScreen },
    { path: '/products/:id', component: SingleProductScreen },
    { path: '/inventory', component: InventoryScreen },
    { path: '/menus', component: MenuItemsScreen },
    { path: '/products', component: ProductsScreen },
    { path: '/discounts/batch', component: BatchGenerateScreen },
    { path: '/discounts', component: DiscountsScreen },
    { path: '/machines/:id', component: SingleMachineScreen },
    { path: '/machines', component: MachinesScreen },
    { path: '/orders/:id', component: SingleOrderScreen },
    { path: '/orders', component: OrdersScreen },
    { path: '/customers', component: CustomerssScreen },
    { path: '/campaigns', component: CampaignsScreen },
    { path: '/campaigns/create', component: AddCampaignScreen },
    { path: '/campaigns/:id', component: CampaignsScreen },
    { path: '/settings', component: SettingsScreen },
    { path: '/settings/:tab', component: SettingsScreen },
    { path: '/settings/machinelocations', component: MachineLocationsScreen },
    { path: '/settings/machinelocations/create', component: MachineLocationScreen },
    { path: '/settings/machinelocations/:id', component: MachineLocationScreen },
    { path: '/settings/locationcategories', component: LocationCategoriesScreen },
    { path: '/settings/locationcategories/create', component: LocationCategoryScreen },
    { path: '/settings/locationcategories/:id', component: LocationCategoryScreen },
    { path: '/settings/locationtypes', component: LocationTypesScreen },
    { path: '/settings/locationtypes/create', component: LocationTypeScreen },
    { path: '/settings/locationtypes/:id', component: LocationTypeScreen },
    { path: '/settings/geographicallocations', component: GeographicalLocationsScreen },
    { path: '/settings/geographicallocations/create', component: GeographicalLocationScreen },
    { path: '/settings/geographicallocations/:id', component: GeographicalLocationScreen },
    { path: '/errors', component: ErrorsScreen },
    { path: '/', component: Dashboard },
  ]

  return (
    <Routes>
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/" element={<ApolloWrapper />}>
        {protectedRoutes.map(({ path, component: C }) => (
          <Route path={path} key={path} element={<Protected component={C} />} />
        ))}
      </Route>
    </Routes>
  )
}

export default App
