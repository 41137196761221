import React, { useState, useEffect, useCallback } from 'react'

import { gql, useQuery, useMutation } from '@apollo/client'

import { useMultilog } from '../context/multilog'

import { makeStyles } from '@material-ui/core/styles'

import { CreateInventory } from './CreateInventory'
import SelectMachine from '../components/selectMachine'

import Stocks from './Stocks'

import Button from '@material-ui/core/Button'

import Paper from '@material-ui/core/Paper'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import Grid from '@material-ui/core/Grid'
import Title from '../components/Title'

import Typography from '@material-ui/core/Typography'

import { useStatefulSearchParam } from '../hooks'
import { getTextForLocale } from '../helpers'
import Spinner from '../components/spinner'

import {
  Category,
  HasMachineHadRefillTodayDocument,
  HasMachineHadRefillTodayQuery,
  MachineByIdDocument,
  MachineByIdQuery,
  Product,
  MenuItem,
  UpdateStockInput,
  SetManyChannelStockDocument,
  SetManyChannelStockMutation,
  StockTransactionV2Input,
  TransactionType,
  StockTransactionV2InventoryInput
} from '../generated'

const INVENTORY_STOCK_TRANSACTION_V2 = gql`
  mutation InventoryStockTransactionV2($input: StockTransactionV2InventoryInput!) {
    inventoryStockTransactionV2(input: $input) {
      balance
    }
  }
`;

const CREATE_STOCK_TRANSACTION_MUTATION_V2 = gql`
  mutation CreateStockTransactionV2($input: StockTransactionV2Input!) {
    createStockTransactionV2(input: $input) {
      id
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  table: { minWidth: 650 },
  toRight: { padding: theme.spacing(2), paddingLeft: '0' },
  toLeft: {
    display: 'flex',
    padding: theme.spacing(2),
    paddingRight: '0',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}))

const TotalRow = ({ title, value }: { title: string; value: number }) => (
  <div style={{ display: 'flex' }}>
    <div style={{ width: '60px', marginRight: '5px' }}>
      <Typography component="p" color="textSecondary" variant="subtitle2">
        {title}
      </Typography>
    </div>
    <Typography component="p" color="textSecondary" variant="subtitle2">
      {value}
    </Typography>
  </div>
)

type WorkingStock = {
  id: string
  quantity: number
  channel: number
  menuItem: MenuItem
}

const InventoryScreen = () => {
  const { multilog, multilogLog } = useMultilog()

  const classes = useStyles()
  const [setManyChannelStock] = useMutation<SetManyChannelStockMutation>(
    SetManyChannelStockDocument
  )
  const [open, setOpen] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [workingStocks, setWorkingStocks] = useState<{
    [key: string]: Omit<WorkingStock, 'id'>
  }>({})
  const [machineId, setMachineId] = useStatefulSearchParam({
    key: 'machineId',
    defaultValue: '96bea44e-7c42-45d9-897d-118fcb8274b7',
  })
  const [isRefill, setIsRefill] = useState<boolean | null>(null)

  const { loading, error, data } = useQuery<MachineByIdQuery>(
    MachineByIdDocument,
    { variables: { id: machineId } }
  )
  const doMachine = useCallback(async () => {
    if (data) {
      await multilogLog(
        multilog?.clientToken || '',
        1,
        'Dashboard.InventoryScreen',
        'Machine selected',
        machineId,
        multilog?.userId || ''
      )
    }    
  }, [data]);
  useEffect(() => {
    if (data) {
      doMachine()
    }
  },[data]);

  const [stockTransactionV2Inventory] = useMutation(INVENTORY_STOCK_TRANSACTION_V2)
  const [createStockTransactionV2] = useMutation(CREATE_STOCK_TRANSACTION_MUTATION_V2)
  const { loading: refillLoading, data: refillData } =
    useQuery<HasMachineHadRefillTodayQuery>(HasMachineHadRefillTodayDocument, {
      variables: { machineId },
    })

  
    const resetWorkingStocks = useCallback(() => {
      setWorkingStocks(
        data?.machineById.menuItems?.reduce(
          (acc, { stocks }) => ({
            ...acc,
            ...stocks?.reduce(
              (acc, { id, quantity, channel, menuItem }) => ({
                ...acc,
                [id]: { quantity, channel, menuItem },
              }),
              {}
            ),
          }),
          {}
        ) ?? {}
      )
    }, [data])

  useEffect(() => {
    setEditMode(false)
  }, [machineId])

  useEffect(() => {
    if (data) {
      resetWorkingStocks()
    }
  }, [data, resetWorkingStocks])

  useEffect(() => {
    if (refillLoading) {
      setIsRefill(null)
    }
  }, [refillLoading])

  useEffect(() => {
    if (refillData?.hasMachineHadRefillToday !== undefined)
      setIsRefill(!refillData.hasMachineHadRefillToday)
  }, [refillData])

  if (loading || !data) return <Spinner />
  if (error) return <p>Error : {JSON.stringify(error, null, 2)}</p>


  const handleEditModeClicked = async () => {
    await multilogLog(
      multilog?.clientToken || '',
      3,
      'Dashboard.InventoryScreen',
      `Edit inventory ${editMode ? 'save' : 'start'}`,
      machineId,
      multilog?.userId || ''
    )
    let todoResupply:StockTransactionV2Input[] = [];
    let todoInventory:StockTransactionV2InventoryInput[] = [];
    if (editMode) {
      // Filters out the stocks that have been changed
      const stocksToUpdate = data.machineById.menuItems
        ?.flatMap(
          (m) =>
            m.stocks?.map((s) => ({
              id: s.id,
              channel: s.channel,
              quantity: s.quantity,
              menuItem: s.menuItem,
            })) as WorkingStock[]
        )
        .filter(
          (s) =>
            s.channel !== +workingStocks[s.id].channel ||
            s.quantity !== +workingStocks[s.id].quantity ||
            s.menuItem.id !== workingStocks[s.id].menuItem.id
        )
        .map((s) => ({
          id: s.id,
          quantity: +workingStocks[s.id].quantity,
          channel: +workingStocks[s.id].channel,
          menuItemId: workingStocks[s.id].menuItem.id,
          productId: workingStocks[s.id].menuItem.product.id
        })) as UpdateStockInput[]
        console.log({ stocksToUpdate})
        // If some quantities are non-numeric reload the stocks
        if (
        stocksToUpdate.some(
          (s) =>
            (s?.quantity && isNaN(s.quantity)) ||
            (s?.channel && isNaN(s.channel))
        )
      ) {
        resetWorkingStocks()
      } else {
        if (isRefill) {
          await Promise.all([
            stocksToUpdate.forEach((s) => {
              const oldrecs = data.machineById.menuItems
              ?.flatMap(
                (m) =>
                  m.stocks?.map((s) => ({
                    id: s.id,
                    channel: s.channel,
                    quantity: s.quantity,
                    menuItem: s.menuItem,
                  }))
              )
              const oldrec = oldrecs.filter(o => 
                o.id === s.id
              )[0];
              let oldBalance = oldrec ? oldrec.quantity : 0;
              // If we have changed channel or product(menuItem),
              // we "delete" (by setting inventory to 0) the old
              // record and queue up the new record in todo array
              multilogLog(
                multilog?.clientToken || '',
                5,
                'Dashboard.InventoryScreen.InventoryScreen.handleEditModeCliecked.isRefill',
                `oldrec: ${JSON.stringify(oldrec)}`,
                multilog?.machineId || '',
                multilog?.userId || ''
              )
              if (
                s.productId !== oldrec.menuItem.product.id ||
                s.channel !== oldrec.channel) {
                  const stockTransactionV2InventoryInput = {
                    machineId,
                    productId: oldrec.menuItem.product.id,
                    channel: oldrec.channel,
                    balance: 0,
                  }
                  multilogLog(
                    multilog?.clientToken || '',
                    6,
                    'Dashboard.InventoryScreen.InventoryScreen.handleEditModeClickedSave.isRefill.stockTransactionV2Inventory',
                    `Changed channel or product id, input: ${JSON.stringify(stockTransactionV2InventoryInput)}`,
                    multilog?.machineId || '',
                    multilog?.userId || ''
                  )
                  stockTransactionV2Inventory({
                    variables: {
                      input: stockTransactionV2InventoryInput
                    }
                  })
                  oldBalance = 0;
                  const todoInput:StockTransactionV2Input = {
                    machineId,
                    productId: s.productId || '',
                    channel: s.channel || 0,
                    quantity: (s.quantity ? s.quantity : 0) - oldBalance,
                    transactionType: TransactionType.Resupply,
                  }
                  todoResupply.push(todoInput)
              } else {
                const createStockTransactionV2Input = {
                  machineId,
                  productId: s.productId,
                  channel: s.channel,
                  quantity: (s.quantity ? s.quantity : 0) - oldBalance,
                  transactionType: 'RESUPPLY',
                }
                multilogLog(
                  multilog?.clientToken || '',
                  6,
                  'Dashboard.InventoryScreen.InventoryScreen.handleEditModeClickedSave.isRefill.createStockTransactionV2',
                  `Same channel and product id, input: ${JSON.stringify(createStockTransactionV2Input)}`,
                  multilog?.machineId || '',
                  multilog?.userId || ''
                )
                createStockTransactionV2({
                  variables: {
                    input: createStockTransactionV2Input
                  }
                })
              }
            }) 
          ]);          
        } else {
          await Promise.all([
            stocksToUpdate.forEach((s) => {
              const oldrecs = data.machineById.menuItems
              ?.flatMap(
                (m) =>
                  m.stocks?.map((s) => ({
                    id: s.id,
                    channel: s.channel,
                    quantity: s.quantity,
                    menuItem: s.menuItem,
                  }))
              )
              // Find the old record for the stock record we are updating
              const oldrec = oldrecs.filter(o => 
                o.id === s.id
              )[0];

              let oldBalance = oldrec ? oldrec.quantity : 0;
              // If we have changed channel or product(menuItem),
              // we "delete" (by setting inventory to 0) the old
              // record and queue up the new record in todo array
              if (
                s.productId !== oldrec.menuItem.product.id ||
                s.channel !== oldrec.channel) {
                const stockTransactionV2InventoryInput = {
                  machineId,
                  productId: oldrec.menuItem.product.id,
                  channel: oldrec.channel,
                  balance: 0,
                }  
                multilogLog(
                  multilog?.clientToken || '',
                  6,
                  'Dashboard.InventoryScreen.InventoryScreen.handleEditModeClickedSave.notRefill.stockTransactionV2Inventory',
                  `Changed channel or product id, input: ${JSON.stringify(stockTransactionV2InventoryInput)}`,
                  multilog?.machineId || '',
                  multilog?.userId || ''
                )
                stockTransactionV2Inventory({
                  variables: {
                    input: stockTransactionV2InventoryInput
                  }
                })

                oldBalance = 0;
                const todoInput:StockTransactionV2InventoryInput = {
                  machineId,
                  productId: s.productId || '',
                  channel: s.channel || 0,
                  balance: (s.quantity ? s.quantity : 0) - oldBalance,
                }
                todoInventory.push(todoInput)
              } else {
                // We have not changed product or channel
                const stockTransactionV2InventoryInput = {
                  machineId,
                  productId: s.productId,
                  channel: s.channel,
                  balance: (s.quantity ? s.quantity : 0),
                }
                multilogLog(
                  multilog?.clientToken || '',
                  6,
                  'Dashboard.InventoryScreen.InventoryScreen.handleEditModeClickedSave.notRefill.stockTransactionV2Inventory',
                  `input: ${JSON.stringify(stockTransactionV2InventoryInput)}`,
                  multilog?.machineId || '',
                  multilog?.userId || ''
                )                                           
                stockTransactionV2Inventory({
                  variables: {
                    input: stockTransactionV2InventoryInput
                  }
                })
              }
            }) 
          ]);
        } 
        await setManyChannelStock({
          variables: {
            input: { stocks: stocksToUpdate, isRefill, machineId },
          },
          refetchQueries: [
            { query: MachineByIdDocument, variables: { id: machineId } },
            {
              query: HasMachineHadRefillTodayDocument,
              variables: { machineId },
            },
          ],
        })
        // If any changed product id or channel, we have them in todo array 
        // and process them here
        if (todoResupply.length) {
          for (let i=0;i<todoResupply.length;i++) {
            await multilogLog(
              multilog?.clientToken || '',
              6,
              'Dashboard.InventoryScreen.InventoryScreen.handleEditModeClickedSave.todoResupply.createStockTransactionV2',
              `input: ${JSON.stringify(todoResupply[i])}`,
              multilog?.machineId || '',
              multilog?.userId || '',
            )
            await createStockTransactionV2({
              variables: {
                  input: todoResupply[i],
              }
            })
          }
        }
        todoResupply = [];
        if (todoInventory.length) {
          await multilogLog(
            multilog?.clientToken || '',
            6,
            'Dashboard.InventoryScreen.InventoryScreen.handleEditModeClickedSave.todoInventory.stockTransactionV2Inventory',
            `input: ${JSON.stringify(todoInventory)}`,
            multilog?.machineId || '',
            multilog?.userId || ''
          )
          for (let i=0;i<todoInventory.length;i++) {
            await stockTransactionV2Inventory({
              variables: {
                input: todoInventory[i],
              }
            })
          }
        }
        todoInventory = [];
      }
      setEditMode(false)
    } else {
      setEditMode(true)
    }
  }

  const handleCancelClick = () => {
    resetWorkingStocks()
    setEditMode(false)
  }

  const total = data.machineById.menuItems?.reduce(
    (acc, curr) => {
      const menuItemQuantity =
        curr.stocks?.reduce((total, { quantity }) => total + quantity, 0) ?? 0
      const categoryKey =
        curr.product?.category === Category.Drink ? 'drinks' : 'food'
      return { ...acc, [categoryKey]: acc[categoryKey] + menuItemQuantity }
    },
    { food: 0, drinks: 0 }
  ) ?? { food: 0, drinks: 0 }

  const sortedMenuItems = data.machineById.menuItems
    ?.slice()
    .filter((m) => m.product && m.product.texts)
    .sort((a, b) =>
      (getTextForLocale(a.product as Product).name ?? '') >
      (getTextForLocale(b.product as Product).name ?? '')
        ? 1
        : -1
    )

  const sortedStocks = data.machineById.menuItems
    ?.flatMap((m) => m.stocks)
    .slice()
    .sort((a, b) => ((a?.channel ?? 0) < (b?.channel ?? 0) ? 1 : -1))

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Title>Inventory</Title>
          <TotalRow value={total.food} title={'Food total'}></TotalRow>
          <TotalRow value={total.drinks} title={'Drink total'}></TotalRow>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.toLeft}>
            <SelectMachine
              machineId={machineId}
              setMachineId={setMachineId}
              allowAll={false}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Channel</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell style={{ minWidth: 150 }}>Quantity</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <Stocks
                    machineId={machineId}
                    loading={loading}
                    error={error}
                    stocks={sortedStocks}
                    menuItems={sortedMenuItems}
                    editMode={editMode}
                    workingStocks={workingStocks}
                    setWorkingStocks={setWorkingStocks}
                    channels={data.machineById.channels}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <CreateInventory
          open={open}
          setOpen={setOpen}
          machineId={machineId}
          menuItems={sortedMenuItems}
          allChannels={data.machineById.channels}
          availableChannels={data.machineById.channels.filter(
            (c) =>
              !data.machineById.menuItems
                ?.flatMap((m) => m.stocks)
                .some((m) => m?.channel === c)
          )}
        />

        <Grid item xs={12}>
          <div className={classes.toLeft}>
            {editMode ? (
              <>
                <FormControlLabel
                  label="Is this a refill?"
                  color="primary"
                  control={
                    <Switch
                      color="primary"
                      disabled={isRefill === null}
                      checked={isRefill || isRefill === null}
                      onChange={(e) => setIsRefill(e.target.checked)}
                    />
                  }
                />
                <Button
                  color={'secondary'}
                  variant="contained"
                  onClick={handleEditModeClicked}
                  style={{ marginRight: '6px' }}
                  disabled={!data}
                >
                  SAVE INVENTORY
                </Button>
                <Button
                  variant="contained"
                  onClick={handleCancelClick}
                  style={{ marginRight: '6px' }}
                >
                  CANCEL
                </Button>
              </>
            ) : (
              <>
                <Button
                  color={'primary'}
                  variant="contained"
                  onClick={handleEditModeClicked}
                  style={{ marginRight: '6px' }}
                  disabled={!data}
                >
                  EDIT INVENTORY
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setOpen(true)}
                >
                  + ADD INVENTORY
                </Button>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default InventoryScreen
