import { useState, useEffect, useCallback } from 'react'

const emptyCampaign = {
  name: '',
}

const useEditCampaign = (initialCampaign) => {
  const [campaign, setCampaign] = useState(initialCampaign || emptyCampaign)

  const resetValues = useCallback(() => {
    setCampaign({
      id: initialCampaign.id,
      name: initialCampaign.name,
    })
  }, [initialCampaign])

  useEffect(() => {
    if (initialCampaign) {
      resetValues()
    }
  }, [initialCampaign, resetValues])

  const handleValueChange = useCallback((value, field) => {
    setCampaign((prevCampaign) => ({
      ...prevCampaign,
      [field]: value,
    }))
  }, [])

  return {
    campaign,
    handleValueChange,
    resetValues,
  }
}

export default useEditCampaign
